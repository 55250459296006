import { Injectable } from '@angular/core';
import { getMainPlans } from 'src/app/apis/plan_api';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UnitServiceService {

  optionLang: string = 'nameTh'
  unit: any = { y: "YEAR", m: "MONTH", d: "DAY" }
  constructor(public translate: TranslateService,) { }

  convertUnit(unit: string) {
    return this.unit[unit]
  }

  optionLangChange() {
    this.optionLang = this.translate.instant('OPTION_ENTH')
    return this.optionLang
  }

  activeUnit(active: boolean) {
    if (active) {
      return "ON"
    } else {
      return "OFF"
    }
  }

  averagePriceDay(unit: string, preriod: number, price: number) {
    var averagePrice = 0
    if (unit == 'y') {
      averagePrice = price / (365 * preriod)
    }

    if (unit == 'm') {
      averagePrice = price / (30 * preriod)
    }

    if (unit == 'd') {
      averagePrice = price / preriod
    }
    return averagePrice
  }

  addExpireDate(unit: string, preriod: number, lastPlanExprieDate: any = null): Date {
    var days = 0
    const currentDate = new Date();
    var endDate = new Date(currentDate);
    if (unit == 'y') {
      days = 365 * preriod
    }
    if (unit == 'm') {
      days = 30 * preriod
    }
    if (unit == 'd') {
      days = preriod
    }
    if (lastPlanExprieDate) {
      endDate = new Date(lastPlanExprieDate)
      days = days + 1
    }
    endDate.setDate(endDate.getDate() + days);
    return endDate;
  }

  deateToExprie(expireDate: string) {
    var exDate = new Date(expireDate)
    const currentDate = new Date();
    // Create a new Date object using the year, month, and day of the given exDate
    var expirationDate = new Date(exDate.getFullYear(), exDate.getMonth(), exDate.getDate());
    var duration = expirationDate.valueOf() - currentDate.valueOf();
    var diffDays = Math.ceil(duration / (1000 * 3600 * 24));
    return diffDays
  }

  generateACCode(id: number) {
    return "AC" + id.toString().padStart(4, '0');
  }

  generateUSCode(id: number) {
    return "US" + id.toString().padStart(4, '0');
  }

  displayBankAccount(accountNo: string) {
    const matches = accountNo.match(/^(\d{3})(\d)(\d{5})(\d)$/);
    if (matches) {
      return `${matches[1]}-${matches[2]}-${matches[3]}-${matches[4]}`;
    } else {
      return accountNo
    }
  }

  showCustomerEditfiled(editFiled: string) {
    editFiled = editFiled.toUpperCase();
    return this.translate.instant(editFiled)
  }

  getUserId() {
    let userId = sessionStorage.getItem("UserId");
    if (!userId) {
      userId = localStorage.getItem("UserId");
    }
    if (userId) {
      return userId
    } else {
      return false
    }
  }

  getCustomerId() {
    let customerId = sessionStorage.getItem("CustomerId");
    if (!customerId) {
      customerId = localStorage.getItem("CustomerId");
    }
    if (customerId) {
      return customerId
    } else {
      return false
    }
  }

}
