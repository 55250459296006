<div id="layout_topbar" class="layout-topbar">
    <div class="sider-toggle"  *ngIf="isLogin()">
        <p-menubar [model]="siderItems"></p-menubar>
    </div>
    <div class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <div class="tob-bar-info menubar-info"  *ngIf="isLogin()" >
            <p-menubar [model]="items" class="font-bold" ></p-menubar>
            <div class="menubar-email">
                {{userEmail }}
            </div>
        </div>
    </div>
    <div class="tob-bar-info"  *ngIf="isLogin()">
        <p-dropdown class="p-4 font-bold" [options]="langList" [(ngModel)]='currentLang' optionLabel="label" optionValue="name" (onChange)="changeLanguage()"></p-dropdown>
    </div>
    <p-dropdown [options]="langList" class="font-bold" [(ngModel)]='currentLang' optionLabel="label" optionValue="name" (onChange)="changeLanguage()" *ngIf="!isLogin()"></p-dropdown>
</div>
