import axios from 'axios';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

const graphq_token_uri = environment.GRAPHQL_TOKEN_URL;

export function getAuthToken() {
  var token = sessionStorage.getItem("token")
  if (!token) {
    token = localStorage.getItem("token")
  }
  return token;
}

export function auth(username: string, password: string) {
  var req_body = JSON.stringify({
    email: username,
    password: password
  })

  const options = {
    method: "POST",
    data: req_body,
    baseURL: graphq_token_uri,
    headers: { 'Content-Type': 'application/json' }
  };

  return axios(options)
    .then((resp: any) => {
      return resp.data;
    })
}

export function getRole() {
  const helper = new JwtHelperService();
  let usertoken = sessionStorage.getItem("token")
  if (!usertoken) {
    usertoken = localStorage.getItem("token")
    if (!usertoken) {
      return ''
    }
  }
  const strusertoken = usertoken as string;
  const decodedToken = helper.decodeToken(strusertoken);
  // Other functions
  const expirationDate = helper.getTokenExpirationDate(strusertoken);
  const isExpired = helper.isTokenExpired(strusertoken);
  if (isExpired) {
    return 'expired'
  }
  return decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
}