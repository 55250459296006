import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenubarModule } from 'primeng/menubar';
import { AppMenuComponent } from './app.menu.component';
import { AppAdminMenuComponent } from './app.adminmenu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { SidebarModule } from 'primeng/sidebar';
import { AppSidebarComponent } from "./app.sidebar.component";
import { AppAdminSidebarComponent } from "./app.adminsidebar.component";
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';


@NgModule({
    declarations: [
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppMenuComponent,
        AppAdminMenuComponent,
        AppSidebarComponent,
        AppAdminSidebarComponent
    ],
    imports: [
        SidebarModule,
        CommonModule,
        RouterModule,
        MenubarModule,
        FormsModule, 
        ReactiveFormsModule,
        TranslateModule,
        DropdownModule,
        MenuModule
    ],
    exports: [
        AppTopBarComponent,
        AppSidebarComponent,
        AppAdminSidebarComponent
    ]
})
export class AppSharedLayoutModule { }
