import { ApolloClient, InMemoryCache, createHttpLink, gql } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { environment } from 'src/environments/environment';
import { getAuthToken } from 'src/app/apis/graphql/authentication';

const graphql_uri = environment.GRAPHQL_URL;


const httpLink = createHttpLink({
    uri: graphql_uri,
  });

export function executeGraphQLClientQuery(graphQLStatement:any){
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: getAuthToken() ? `Bearer ${getAuthToken()}` : "",
      }
    }
  });

  const graphql_client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
  });

  return graphql_client.query(graphQLStatement);
}

export function executeGraphQLClientMutation(graphQLStatement:any){

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: getAuthToken() ? `Bearer ${getAuthToken()}` : "",
      }
    }
  });

  const graphql_client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
  });

  return graphql_client.mutate(graphQLStatement);
}
