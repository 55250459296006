import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];

    constructor(
        public layoutService: LayoutService,
        public translate: TranslateService
    ) { 
        translate.onLangChange.subscribe((e) => {
            this.setModel();
        });
    }

    ngOnInit() {
        this.setModel();
    }

    private setModel(){
        this.model = [
            {
                items: [
                    { label: this.translate.instant('ACCOUNT_OVERVIEW'), icon: 'pi pi-fw pi-home', routerLink: ['/'], active: true },
                ]
            },
            {
                items: [
                    { label: this.translate.instant('PERSONAL_INFORMATION'), icon: 'pi pi-fw pi-user', routerLink: ['/user_information'] },
                ]
            },
            {
                items: [
                    { label: this.translate.instant('TAX_INVOICE_DATA'), icon: 'pi pi-id-card', routerLink: ['/tax_invoice_data'] },
                ]
            },
            {
                items: [
                    { label: this.translate.instant('REQUEST_A_REFUND_OF_WITHHOLDING_TAX'), icon: 'pi pi-id-card', routerLink: ['/tax_refund'] },
                ]
            },
            {
                items: [
                    { label: this.translate.instant('CHANGE') + this.translate.instant('PASSWORD'), icon: 'pi pi-fw pi-lock', routerLink: ['/change_password'] },
                ]
            },
            {
                items: [
                    { label: this.translate.instant('RESET') + ' PIN', icon: 'pi pi-fw pi-key', routerLink: ['/reset_pin'] },
                ]
            },
            {
                items: [
                    { label: this.translate.instant('PACKAGE'), icon: 'pi pi-fw pi-credit-card', routerLink: ['/edit_plan'] },
                ]
            },
            {
                items: [
                    { label: this.translate.instant('PAYMENT_HISTORY'), icon: 'pi pi-fw pi-dollar', routerLink: ['/paid_history'] },
                ]
            },
            {
                items: [
                    { label: this.translate.instant('Report'), icon: 'pi pi-fw pi-chart-line', routerLink: ['/report'] },
                ]
            }
        ];

    }
}
