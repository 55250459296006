import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {

    menuMode = 'static';
    

    constructor(private primengConfig: PrimeNGConfig,public translate: TranslateService) {
        const supportedLangs = ['th','en'];
        translate.addLangs(supportedLangs)
        translate.setDefaultLang('th');
        let lang = sessionStorage.getItem('language')
        if(lang && lang != ''){
            translate.use(lang as string);
        } else {
            translate.use('th');
        }
        supportedLangs.forEach((language) => {
            this.translate.reloadLang(language);
        });
    }

    
    ngOnInit() {
        this.primengConfig.ripple = true;
        document.documentElement.style.fontSize = '14px';
    }
}
