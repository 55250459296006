import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from "./layout/app.layout.component";

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppLayoutComponent,
                children: [
                    { path: '', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
                    { path: 'admin', loadChildren: () => import('./modules/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule) },
                    { path: 'signin', loadChildren: () => import('./modules/auth/login/login.module').then(m => m.LoginModule) },
                    { path: 'signin_admin', loadChildren: () => import('./modules/auth/login-admin/login-admin.module').then(m => m.LoginAdminModule) },
                    { path: 'signup', loadChildren: () => import('./modules/signup/signup.module').then(m => m.SignupModule) },
                    { path: 'forgotpassword', loadChildren: () => import('./modules/forgotpassword/forgotpassword.module').then(m => m.ForgotPasswordModule) },
                    { path: 'resetpassword', loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
                    { path: 'changelog', loadChildren: () => import('./modules/change-log/change-log.module').then(m => m.ChangeLogModule) },
                ],
            },
            { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
            { path: '**', redirectTo: 'pages/notfound' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {

    ngOnInit() {

    }
}
