import { executeGraphQLClientMutation, executeGraphQLClientQuery } from 'src/app/apis/graphql/graphql_api';
import { gql } from '@apollo/client/core';
import { Customer } from 'src/app/models/models'

export function getCustomerById(id: number){
    return executeGraphQLClientQuery({
        query: gql`
            query{
                customerById(id:${ id.toString() }){
                    id
                    fullName
                    pincode
                    businessName
                    email
                    phone
                    postCode
                    province
                    address
                    district
                    subDistrict
                    isActive
                    businessType {
                        id
                        description
                        name
                    }
                    planOrder{
                        id
                        totalPrice
                        planId
                        planOrderStatusId
                        paymentMethodId
                        expireDate
                        startDate
                        rejectPayment {
                            id
                            nameTh
                            nameEn
                        }
                        planOrderStatus {
                            nameTh
                            nameEn
                        }
                        plan {
                          id
                          nameEn
                          nameTh
                          price
                          period
                          periodUnit
                          mainPlanId
                          detailFirst
                          detailSecond
                          mainPlan {
                            nameTh
                            nameEn
                          }
                        }
                      }
                }
            }
        `}).then((res:any) => {
            return res.data.customerById;
        });
}

export function getCustomerByUserId(userId: string){
  return executeGraphQLClientQuery({
      query: gql`
          query{
            customerByUserId(userId: "${ userId }"){
              id
              fullName
              pincode
              businessName
              email
              phone
              postCode
              province
              address
              district
              subDistrict
              isActive
              businessType {
                id
                description
                name
              }
              planOrder{
                id
                totalPrice
                planId
                planOrderStatusId
                paymentMethodId
                expireDate
                startDate
                rejectPayment {
                    id
                    nameTh
                    nameEn
                }
                planOrderStatus {
                  nameTh
                  nameEn
                }
                plan {
                  id
                  nameEn
                  nameTh
                  price
                  period
                  periodUnit
                  mainPlanId
                  detailFirst
                  detailSecond
                  mainPlan {
                    nameEn
                    nameTh
                  }
                }
              }
            }

          }
      `}).then((res:any) => {
          return res.data.customerByUserId;
      });
}

export function getCustomers(){
    return executeGraphQLClientQuery({
        query: gql`
            query{
                customers{
                    id
                    fullName
                    pincode
                    businessName
                    email
                    isActive
                    planOrder{
                        expireDate
                        plan {
                            nameEn
                            nameTh
                            mainPlan {
                                nameEn
                                nameTh
                            }
                        }
                    }

                }
            }
        `}).then((res:any) => {
            return res.data.customers;
        });
}



export function checkIsExistEmail(email: string){
    return executeGraphQLClientQuery({
        query: gql`
            query{
                emailIsExsiting(email:"${ email }"){
                    isExist
                }
            }
        `}).then((res:any) => {
            return res.data.emailIsExsiting.isExist;
        });
}


export function getEmailByToken(token: string){
  return executeGraphQLClientQuery({
      query: gql`
          query{
              emailByToken(token:"${ token }"){
                  email
              }
          }
      `}).then((res:any) => {
          return res.data.emailByToken.email;
      });
}

export function resetPassword(token: string|null, password: string|null, email: string|null){
  return executeGraphQLClientMutation({
      mutation : gql`
          mutation{
              resetPassword(model:{
                  token: "${ token }"
                  password: "${ password }"
                  email: "${ email }"
              }){
                  id
                  success
                  message
              }
          }
      `}).then((res:any) => {
          return res.data.resetPassword;
      });
}

export function updateCustomer(customer: Customer){
    return executeGraphQLClientMutation({
        mutation : gql`
            mutation{
                updateCustomer(model:{
                    id: ${ customer.id },
                    fullName: "${ customer.fullName }",
                    email: "${ customer.email }",
                    phone: "${ customer.phone }",
                    pincode: "${ customer.pincode}",
                    businessName: "${ customer.businessName }",
                    businessTypeId: ${ customer.businessTypeId },
                    address:"${ customer.address }",
                    province:"${ customer.province }",
                    district:"${ customer.district }",
                    subDistrict:"${ customer.subDistrict }",
                    postCode:"${ customer.postCode }",
                    isActive:${ customer.isActive }
                }){
                    id
                    success
                    message
                    errors
                    userId
                }
            }
        `}).then((res:any) => {
            return res.data.updateCustomer;
        });
  }
