import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { getRole } from 'src/app/apis/graphql/authentication';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router) { }

  isLoggedIn() {
    let userLogin = sessionStorage.getItem("UserId");
    if (!userLogin) {
      userLogin = localStorage.getItem("UserId");
    }
    if (userLogin) {
      const helper = new JwtHelperService();
      let usertoken = sessionStorage.getItem("token")
      if (!usertoken) {
        usertoken = localStorage.getItem("token");
        if (!usertoken) {
          return false
        }
      }
      if (!usertoken) {
        return false;
      }
      const isExpired = helper.isTokenExpired(usertoken as string);
      if (isExpired) {
        return false;
      }
      return true;
    } else {
      return false
    }
  }

  isAdmin() {
    const userRole = getRole()
    if (userRole == 'SuperAdmin' || userRole == 'Admin') {
      return true;
    } else {
      return false
    }
  }

  logout(): void {
    const userRole = getRole()
    sessionStorage.clear();
    localStorage.clear();
    if (userRole == 'SuperAdmin' || userRole == 'Admin') {
      this.router.navigate(['/signin_admin']);
    } else {
      this.router.navigate(['/signin']);
    }
  }

}
