import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { BadgeModule } from 'primeng/badge';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RippleModule } from 'primeng/ripple';
import { RouterModule } from '@angular/router';
import { AppConfigModule } from './config/config.module';
import { AppLayoutComponent } from "./app.layout.component";
import { AppSharedLayoutModule } from "./app.layout-shared.module";
import { MenubarModule } from 'primeng/menubar';
import { TableModule } from 'primeng/table';
import { TranslateModule } from '@ngx-translate/core';
import { MenuModule } from 'primeng/menu';

@NgModule({
    declarations: [
        AppLayoutComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        InputTextModule,
        BadgeModule,
        RadioButtonModule,
        InputSwitchModule,
        RippleModule,
        RouterModule,
        AppConfigModule,
        MenubarModule,
        AppSharedLayoutModule,
        TableModule,
        TranslateModule,
        MenuModule
    ],
    exports: [AppLayoutComponent]
})
export class AppLayoutModule { }
