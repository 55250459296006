import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-adminmenu',
    templateUrl: './app.adminmenu.component.html'
})
export class AppAdminMenuComponent implements OnInit {
    model: any[] = [];
    
    constructor(
        public layoutService: LayoutService,
        public translate: TranslateService
    ) { 
        translate.onLangChange.subscribe((e) => {
            this.setModel();
        });
    }

    
    ngOnInit() {
        this.setModel();
    }

    private setModel(){
        this.model = [
            {
                items: [
                    { label: this.translate.instant('CUSTOMER_LIST'), icon: 'pi pi-fw pi-users', routerLink: ['/admin/customer'], active: true },
                ]
            },
            {
                items: [
                    { label: this.translate.instant('MANAGE_PACKAGE'), icon: 'pi pi-fw pi-book', routerLink: ['/admin/packages'] },
                ]
            },
            {
                items: [
                    { label: this.translate.instant('BANK_DATA'), icon: 'pi pi-fw pi-book', routerLink: ['bank'] },
                ]
            },
            {
                items: [
                    { label: this.translate.instant("ORDERS"), icon: 'pi pi-fw pi-wallet', routerLink: ['/admin/plan_orders'] },
                ]
            },
            {
                items: [
                    { label: this.translate.instant('MANAGE_USER'), icon: 'pi pi-fw pi-user-edit', routerLink: ['/admin/users'] },
                ]
            },
            {
                items: [
                    { label: this.translate.instant('PERSONAL_INFORMATION'), icon: 'pi pi-fw pi-user', routerLink: ['/admin/edit_users'] },
                ]
            }
        ];
    }
}
