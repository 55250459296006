import { Component, OnInit, ElementRef, Renderer2, AfterViewInit, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { getCustomerByUserId } from 'src/app/apis/customer_api';
import { getRole } from 'src/app/apis/graphql/authentication';
import { UnitServiceService } from '../services/unit-service.service';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    items!: MenuItem[];
    siderItems!: MenuItem[];
    langList: any;
    currentLang = 'th';
    currentUser: string = '';
    userEmail: string = '';
    singOutRoute = '/signin'
    userInformationElement!: HTMLElement;
    @Input() dasbaordType: string = ''

    constructor(
        public layoutService: LayoutService,
        private _router: Router,
        public translate: TranslateService,
        private el: ElementRef,
        private renderer: Renderer2,
        public unitServiceService: UnitServiceService,
        public auth: AuthService
    ) {
        translate.onLangChange.subscribe((e) => {
            this.setModel();
        });
    }

    async ngOnInit() {
        if (getRole() == 'Administrator') {
            this.singOutRoute = 'signin_admin'
        }

        let getLang = sessionStorage.getItem('language');
        if (!getLang) {
            getLang = 'th';
        }
        this.translate.use(getLang)

        var customerIdSession = await this.unitServiceService.getUserId();

        if (customerIdSession) {
            await getCustomerByUserId(customerIdSession).then((user) => {


                this.currentUser = user.fullName
                this.userEmail = user.email

            });
        }

        this.setModel();

        this.items = [
            {
                label: this.currentUser,
                items: [
                    {
                        label: this.translate.instant('SIGN_OUT'),
                        icon: 'pi pi-fw pi-sign-out',
                        command: (event: Event) => { this.auth.logout() }
                    }
                ]
            }
        ]

        this.langList = [
            { name: 'th', label: 'ไทย' },
            { name: 'en', label: 'En' },
        ];

    }


    setModel() {
        if (this.dasbaordType == 'customer') {
            this.siderItems = [
                {
                    label: this.currentUser,
                    items: [
                        {
                            label: this.translate.instant('SIGN_OUT'),
                            icon: 'pi pi-fw pi-sign-out',
                            command: (event: Event) => { this._router.navigateByUrl(this.singOutRoute) }
                        }
                    ]
                },
                { label: this.translate.instant('ACCOUNT_OVERVIEW'), icon: 'pi pi-fw pi-home', routerLink: ['/'] },
                { label: this.translate.instant('PERSONAL_INFORMATION'), icon: 'pi pi-fw pi-user', routerLink: ['/user_information'] },
                { label: this.translate.instant('TAX_INVOICE_DATA'), icon: 'pi pi-id-card', routerLink: ['/tax_invoice_data'] },
                { label: this.translate.instant('REQUEST_A_REFUND_OF_WITHHOLDING_TAX'), icon: 'pi pi-id-card', routerLink: ['/tax_refund'] },
                { label: this.translate.instant('CHANGE') + this.translate.instant('PASSWORD'), icon: 'pi pi-fw pi-lock', routerLink: ['/change_password'] },
                { label: this.translate.instant('RESET') + ' PIN', icon: 'pi pi-fw pi-key', routerLink: ['/reset_pin'] },
                { label: this.translate.instant('PACKAGE'), icon: 'pi pi-fw pi-credit-card', routerLink: ['/edit_plan'] },
                { label: this.translate.instant('PAYMENT_HISTORY'), icon: 'pi pi-fw pi-dollar', routerLink: ['/paid_history'] },
                { label: this.translate.instant('Report'), icon: 'pi pi-fw pi-chart-line', routerLink: ['/report'] },
            ];
        } else {
            this.siderItems = [
                {
                    label: this.currentUser,
                    items: [
                        {
                            label: this.translate.instant('SIGN_OUT'),
                            icon: 'pi pi-fw pi-sign-out',
                            command: (event: Event) => { this._router.navigateByUrl(this.singOutRoute) }
                        }
                    ]
                },
                { label: this.translate.instant('CUSTOMER_LIST'), icon: 'pi pi-fw pi-users', routerLink: ['/admin/customer'] },
                { label: this.translate.instant('MANAGE_PACKAGE'), icon: 'pi pi-fw pi-book', routerLink: ['/admin/packages'] },
                { label: this.translate.instant('BANK_DATA'), icon: 'pi pi-fw pi-book', routerLink: ['bank'] },
                { label: this.translate.instant("ORDERS"), icon: 'pi pi-fw pi-wallet', routerLink: ['/admin/plan_orders'] },
                { label: this.translate.instant('MANAGE_USER'), icon: 'pi pi-fw pi-user-edit', routerLink: ['/admin/users'] },
                { label: this.translate.instant('PERSONAL_INFORMATION'), icon: 'pi pi-fw pi-user', routerLink: ['/admin/edit_users'] },
            ];
        }
    }

    isLogin() {
        return this.unitServiceService.getUserId();
    }

    changeLanguage() {
        sessionStorage.setItem('language', this.currentLang)
        this.translate.use(this.currentLang)
    }

    showSideBar() {
        var siderElement = document.querySelector('.app-sider') as HTMLDivElement
        siderElement.style.display = "block";

    }
}
